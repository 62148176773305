import type { ReactNode } from 'react';
import type { SnackbarOptions, AlertOptions } from 'types';
import type { FetchResponseError } from 'types/fetch';
import type { Connection } from 'types/store';
import {
    ALERT_CLOSE,
    ALERT_OPEN,
    BACK_BUTTON_HIDE,
    BACK_BUTTON_SHOW,
    FETCH_BEGIN,
    FETCH_END,
    FETCH_FAIL,
    LOADING_START,
    LOADING_STOP,
    ON_CONNECTION,
    ON_PAGE_READY,
    ON_PUSH_SUBSCRIPTION,
    SNACKBAR_CLOSE,
    SNACKBAR_OPEN,
    TAB_CHANGE
} from '../types';

export const loadingStart = () =>
    ({
        type: LOADING_START
    } as const);

export const loadingStop = () =>
    ({
        type: LOADING_STOP
    } as const);

export const fetchBegin = (params: object) =>
    ({
        type: FETCH_BEGIN,
        payload: {
            params
        }
    } as const);

export const fetchEnd = (response: object) =>
    ({
        type: FETCH_END,
        payload: {
            response
        }
    } as const);

export const fetchFail = (error: FetchResponseError) =>
    ({
        type: FETCH_FAIL,
        payload: {
            error
        }
    } as const);

export const snackbarOpen = (message: string, options?: SnackbarOptions) =>
    ({
        payload: {
            message,
            options
        },
        type: SNACKBAR_OPEN
    } as const);

export const snackbarClose = () =>
    ({
        type: SNACKBAR_CLOSE
    } as const);
export const alertOpen = (message: ReactNode, options: AlertOptions = {}) =>
    ({
        payload: {
            message,
            options
        },
        type: ALERT_OPEN
    } as const);

export const alertClose = () =>
    ({
        type: ALERT_CLOSE
    } as const);

export const tabChange = (index: number) =>
    ({
        payload: {
            index
        },
        type: TAB_CHANGE
    } as const);

export const backButtonShow = () =>
    ({
        type: BACK_BUTTON_SHOW
    } as const);

export const backButtonHide = () =>
    ({
        type: BACK_BUTTON_HIDE
    } as const);

export const onPageReady = () =>
    ({
        type: ON_PAGE_READY
    } as const);

export const onConnection = (type: Connection) =>
    ({
        type: ON_CONNECTION,
        payload: {
            type
        }
    } as const);

export const onPushSubscription = (isPushEnabled: boolean) =>
    ({
        type: ON_PUSH_SUBSCRIPTION,
        payload: {
            isPushEnabled
        }
    } as const);
