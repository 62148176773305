import type { Middleware } from 'redux';
import {
    legacy_createStore as createStore,
    applyMiddleware,
    compose
} from 'redux';
import thunk from 'redux-thunk';
import player from 'middlewares/player';
import env from 'env';
import app from 'middlewares/app';
import auth from 'middlewares/auth';
import ga from 'middlewares/ga';
import storage from 'middlewares/storage';
import api from 'middlewares/api';
import reducers from 'reducers';
// import logger from 'redux-logger';

const isProd = env.NODE_ENV === 'production';
const isTest = env.NODE_ENV === 'test';
const composerEnhancer =
    (!(isProd || isTest) &&
        typeof window !== 'undefined' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            // @ts-ignore
            trace: true
        })) ||
    compose;

const middlewares: Middleware[] = [app, auth, ga, player, api];

middlewares.unshift(thunk);
middlewares.push(storage);

// if (!isProd && !isTest) {
//     middlewares.push(logger);
// }

const store = createStore(
    reducers,
    composerEnhancer(applyMiddleware.apply(null, middlewares))
);

if (typeof window.Cypress !== 'undefined') {
    window.store = store;
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export type Store = typeof store;

export default store;
