import type { User } from 'types';
import { getUserPictures } from './get-user-pictures';

export const getUserPicture = (user: User) => {
    const pictures = getUserPictures(user);

    if (pictures) {
        return pictures[0];
    }

    return null;
};
