import reduce from 'lodash/reduce';
import { CART_ADD_ITEM, CART_CLEAR } from 'actions/types';
import type { Cart } from 'types';
import type Actions from 'actions';
import localStorage from 'middlewares/storage/localStorage';

const cart = localStorage.get<Cart>('cart');
const initialState: Cart = cart || {
    id: '',
    tickets: {},
    totalAmount: 0,
    currency: 'NGN'
};

const reducers = (state = initialState, action: Actions): Cart => {
    switch (action.type) {
        case CART_ADD_ITEM: {
            const { item, id, currency } = action.payload;
            const tickets = state.id !== id ? {} : state.tickets;

            if (item.id) {
                tickets[item.id] = item;

                if (tickets[item.id]?.quantity === 0) {
                    delete tickets[item.id];
                }
            }

            const totalAmount = reduce(
                tickets,
                (sum, ticket) => sum + ticket.quantity * ticket.price,
                0
            );

            return {
                ...state,
                ...{
                    id,
                    tickets,
                    totalAmount,
                    currency
                }
            };
        }

        case CART_CLEAR: {
            return {
                id: '',
                tickets: {},
                totalAmount: 0,
                currency: 'NGN'
            };
        }

        default:
            return state;
    }
};

export default reducers;
