import type { Middleware } from 'redux';
import { setDefaultEventParameters } from 'firebase/analytics';
import {
    CART_ADD_ITEM,
    FETCH_TOKEN,
    FETCH_FAIL,
    FETCH_LOGOUT,
    ON_PAGE_READY,
    FETCH_USERS_SUCCESS,
    ON_STOP,
    ON_PAUSE,
    ON_PLAY,
    ON_NEXT,
    ON_PREV,
    ON_REPEAT,
    ON_SHUFFLE,
    ON_VOLUME_CHANGE,
    ON_PLAYER_READY
} from 'actions/types';
import { getAppInfo } from 'helpers/app';
import { isInternalEmail } from 'helpers/user';
import type { RootState, AppDispatch } from 'store';
import type { Scope } from 'overrides/sentry';
import { setUser, configureScope } from 'overrides/sentry';
import {
    logAnalyticsCustomEvent,
    logAnalyticsEvent,
    setAnalyticsUserId,
    setAnalyticsUserProperties
} from 'overrides/firebase.analytics';
import type Actions from 'actions';
import type { SessionId } from 'types';
import { getCurrentTime } from 'shared/web-player';

let playing: NodeJS.Timeout;
/* eslint-disable camelcase*/

document.addEventListener('securitypolicyviolation', e => {
    logAnalyticsCustomEvent('csp-violation', {
        event_category: 'security',
        non_interaction: true,
        'blocked-uri': e.blockedURI,
        'document-uri': e.documentURI,
        'effective-directive': e.effectiveDirective,
        'original-policy': e.originalPolicy,
        referrer: e.referrer,
        'violated-directive': e.violatedDirective
    });
});

const ga: Middleware<{}, RootState, AppDispatch> = ({ getState }) => {
    return next =>
        function ga(action: Actions) {
            const result = next(action);

            switch (action.type) {
                case ON_PAGE_READY: {
                    const { shell } = getState();
                    const { sessionId } = shell;
                    const { version } = getAppInfo();

                    const data: {
                        app_version: string;
                        session_id: SessionId;
                    } = {
                        app_version: version,
                        session_id: sessionId
                    };

                    setDefaultEventParameters(data);

                    logAnalyticsCustomEvent('page_ready');

                    setAnalyticsUserProperties(data);

                    break;
                }
                case FETCH_LOGOUT:
                    logAnalyticsCustomEvent('logout', {
                        user_id: null
                    }); // Set the user ID using signed-in user_id.

                    configureScope((scope: Scope) => scope.setUser(null));
                    setAnalyticsUserProperties({
                        // eslint-disable-next-line camelcase
                        is_internal_user: false,
                        user_internal_id: null
                    });
                    setAnalyticsUserId('');
                    break;
                case FETCH_TOKEN: {
                    const { loginType = 'Unknown', method } = action.payload;

                    logAnalyticsEvent('login', {
                        method: method === 'get' ? 'Auto' : loginType
                    });
                    break;
                }
                case FETCH_USERS_SUCCESS: {
                    const {
                        meta: {
                            // @ts-ignore
                            originalAction: {
                                payload: { method, options }
                            }
                        },
                        data = {}
                    } = action.payload;

                    if (method === 'post') {
                        if (data?.code === 201) {
                            let signUpMethod = 'Manual';

                            if (options?.data?.signInMethod) {
                                signUpMethod = options?.data?.signInMethod;
                            }

                            logAnalyticsEvent('sign_up', {
                                method: signUpMethod
                            });
                        }
                    } else if (method === 'get') {
                        logAnalyticsCustomEvent('login_success', {
                            user_id: data.id
                        }); // Set the user ID using signed-in user_id.

                        setUser({
                            email: data.email,
                            user_internal_id: data.id
                        });
                        setAnalyticsUserProperties({
                            // eslint-disable-next-line camelcase
                            is_internal_user: isInternalEmail(data.email),
                            user_internal_id: data.id
                        });
                        setAnalyticsUserId(data.id);
                    }

                    break;
                }
                case FETCH_FAIL: {
                    const { error } = action.payload;

                    logAnalyticsEvent('exception', {
                        description: error.message,
                        fatal: true
                    });
                    break;
                }
                case CART_ADD_ITEM: {
                    const { item, currency } = action.payload;

                    logAnalyticsEvent('add_to_cart', {
                        currency,
                        value: item.price,
                        items: [item]
                    });
                    break;
                }
                case ON_PLAYER_READY: {
                    const { player } = getState();
                    const { volume, mute, shuffle, status } = player;

                    logAnalyticsCustomEvent('player_ready', {
                        volume,
                        mute,
                        shuffle,
                        status,
                        event_category: 'Audio',
                        non_interaction: true
                    });
                    break;
                }
                case ON_STOP:
                case ON_PAUSE: {
                    clearInterval(playing);
                    const { player } = getState();
                    const position = getCurrentTime();

                    logAnalyticsCustomEvent(player.status, {
                        position,
                        event_category: 'Audio'
                    });
                    break;
                }
                case ON_PLAY: {
                    const { player } = getState();
                    const { status, playlist, currentItemIndex } = player;
                    const track = playlist.items[currentItemIndex];
                    const label = `${track.title} - ${track.artist.stageName}`;
                    const position = getCurrentTime();

                    logAnalyticsCustomEvent('play', {
                        event_category: 'Audio',
                        event_label: label,
                        position,
                        status
                    });
                    break;
                }
                case ON_NEXT: {
                    logAnalyticsCustomEvent('next', {
                        event_category: 'Audio'
                    });
                    break;
                }
                case ON_PREV: {
                    logAnalyticsCustomEvent('prev', {
                        event_category: 'Audio'
                    });
                    break;
                }
                case ON_VOLUME_CHANGE: {
                    const { volume } = action.payload;

                    logAnalyticsCustomEvent('volume', {
                        event_category: 'Audio',
                        volume
                    });
                    break;
                }
                case ON_REPEAT: {
                    const { repeat } = action.payload;

                    logAnalyticsCustomEvent('repeat', {
                        event_category: 'Audio',
                        repeat
                    });
                    break;
                }
                case ON_SHUFFLE: {
                    const { shuffle } = getState().player;

                    logAnalyticsCustomEvent('shuffle', {
                        event_category: 'Audio',
                        shuffle
                    });
                    break;
                }
                default:
            }

            return result;
        };
    /* eslint-enable camelcase*/
};

export default ga;
