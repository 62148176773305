import { lazy } from 'react';

const StripeAccount = lazy(
    () => import(/* webpackChunkName: "stripe-account" */ './StripeAccount')
);

export default lazy(
    () => import(/* webpackChunkName: "cashout" */ './CashOut')
);

export { StripeAccount };
