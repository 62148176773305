import { createContext } from 'react';
import type { User } from 'types';

type Context = {
    user?: User | null;
    size?: {
        width: number;
        height: number;
    };
};

const ShellContext = createContext<Context | undefined>(undefined);

ShellContext.displayName = 'ShellContext';

export default ShellContext;
