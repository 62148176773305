import { createContext } from 'react';
import type { ColorSchemeOption } from 'types';

const ThemeContext = createContext<{
    colorScheme: ColorSchemeOption;
    setColorScheme: (value: ColorSchemeOption) => void;
}>({
    colorScheme: 'dark',
    setColorScheme: () => {
        throw new Error('setColorScheme is not defined');
    }
});

ThemeContext.displayName = 'ThemeContext';

export default ThemeContext;
