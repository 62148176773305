import {
    LOAD,
    ON_BUFFER_CHANGE,
    ON_LOAD,
    ON_CAST_CONNECT,
    ON_CAST_DISCONNECT,
    ON_CAST_DEVICE_NAME_CHANGE,
    ON_CAST_STATE_CHANGE,
    ON_PAUSE,
    ON_PAUSED,
    ON_PLAY,
    ON_PLAYER_READY,
    ON_PLAYING,
    ON_PLAYLIST_ITEM,
    ON_REPEAT,
    ON_SHUFFLE,
    ON_STOP,
    ON_VOLUME_CHANGE,
    ON_ADD_TO_QUEUE,
    ON_REMOVE_FROM_QUEUE,
    ON_AUTOPLAY,
    ON_ERROR
} from 'actions/types';
import { findTrackIndex } from 'helpers/track';
import { isSamePlaylist } from 'helpers/is-same-playlist';
import type { Player, PlayerPlaylist, QueueId } from 'types';
import type Actions from 'actions';
import { Rights } from 'enums/rights';

const playlist: PlayerPlaylist = {
    // id: '',
    items: []
};
const initialState: Player = {
    bufferPercent: 0,
    currentItemIndex: 0,
    device: 'local',
    deviceName: '',
    isCastAvailable: false,
    isReady: false,
    mute: false,
    playlist,
    repeat: 'all',
    shuffle: false,
    status: 'idle',
    // type: 'album',
    // typeId: 0,
    volume: 80,
    params: {
        id: 'player-initial-id' as QueueId
    },
    autoplay: true
};

const reducers = (state = initialState, action: Actions): Player => {
    switch (action.type) {
        case ON_PLAYLIST_ITEM: {
            const { /* index,*/ trackId } = action.payload;
            const { /* currentItemIndex, currentItemId,*/ playlist } = state;

            const trackIndex = findTrackIndex(playlist.items, trackId);

            return {
                ...state,
                ...{
                    bufferPercent: 0,
                    currentItemId: trackId,
                    currentItemIndex: trackIndex
                }
            };
        }
        case ON_ERROR: {
            return {
                ...state,
                ...{
                    status: 'error'
                }
            };
        }
        case ON_PLAY:
        case ON_PLAYING:
            return {
                ...state,
                ...{
                    status: 'playing'
                }
            };
        case ON_PAUSE:
        case ON_PAUSED: {
            return {
                ...state,
                ...{
                    status: 'paused'
                }
            };
        }
        case ON_STOP:
            return {
                ...state,
                ...{
                    status: 'idle'
                }
            };
        case ON_LOAD:
            return {
                ...state,
                ...{
                    status: 'loading'
                }
            };
        case ON_BUFFER_CHANGE: {
            const { bufferPercent } = action.payload;

            return {
                ...state,
                ...{
                    bufferPercent
                }
            };
        }
        case LOAD: {
            const { playlist, trackId, params } = action.payload;
            const { items, id } = playlist;

            if (isSamePlaylist(state.params, params)) {
                return state;
            }

            let currentItemIndex = 0;

            if (trackId) {
                currentItemIndex = findTrackIndex(items, trackId);

                if (currentItemIndex === -1) {
                    currentItemIndex = 0;
                }
            }

            if (items.length) {
                const track = items[currentItemIndex];

                if (track.rights === Rights.READ) {
                    return state;
                }
            }

            return {
                ...state,
                ...{
                    bufferPercent: 0,
                    currentItemId: trackId,
                    currentItemIndex,
                    playlist: {
                        ...state.playlist,
                        ...{
                            items,
                            id
                        }
                    },
                    params
                }
            };
        }
        case ON_PLAYER_READY:
            return {
                ...state,
                ...{
                    isReady: true,
                    status: 'idle'
                }
            };
        case ON_SHUFFLE:
            return {
                ...state,
                ...{
                    shuffle: !state.shuffle
                }
            };
        case ON_REPEAT: {
            const { repeat } = action.payload;

            return {
                ...state,
                ...{
                    repeat
                }
            };
        }
        case ON_CAST_STATE_CHANGE: {
            const { value } = action.payload;
            const isCastAvailable =
                value !== cast.framework.CastState.NO_DEVICES_AVAILABLE;

            return {
                ...state,
                ...{
                    isCastAvailable
                }
            };
        }
        case ON_CAST_DEVICE_NAME_CHANGE: {
            const { deviceName } = action.payload;

            return {
                ...state,
                ...{
                    deviceName
                }
            };
        }
        case ON_CAST_CONNECT: {
            return {
                ...state,
                ...{
                    device: 'remote'
                }
            };
        }
        case ON_CAST_DISCONNECT: {
            return {
                ...state,
                ...{
                    device: 'local'
                }
            };
        }
        case ON_VOLUME_CHANGE: {
            const { volume } = action.payload;

            if (state.volume === volume) {
                return state;
            }

            return {
                ...state,
                ...{
                    volume
                }
            };
        }
        case ON_ADD_TO_QUEUE: {
            const { tracks } = action.payload;

            const tracksNotInQueue = tracks.filter(
                (track: { id: number | undefined }) => {
                    return (
                        findTrackIndex(state.playlist.items, track.id) === -1
                    );
                }
            );

            if (tracksNotInQueue.length === 0) {
                return state;
            }

            return {
                ...state,
                ...{
                    playlist: {
                        ...state.playlist,
                        ...{
                            items: [
                                ...state.playlist.items,
                                ...tracksNotInQueue
                            ]
                        }
                    }
                }
            };
        }
        case ON_REMOVE_FROM_QUEUE: {
            const { trackId } = action.payload;

            return {
                ...state,
                ...{
                    playlist: {
                        ...state.playlist,
                        ...{
                            items: state.playlist.items.filter(
                                track => track.id !== trackId
                            )
                        }
                    }
                }
            };
        }
        case ON_AUTOPLAY: {
            const { autoplay } = action.payload;

            return {
                ...state,
                ...{
                    autoplay
                }
            };
        }
        default:
            return state;
    }
};

export default reducers;
