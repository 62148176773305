import type { ReactNode } from 'react';
import { useState, useMemo, Suspense, lazy } from 'react';
import type { BuyNowMusicData } from 'contexts/buy-now-music';
import BuyNowContext from 'contexts/buy-now-music';
const BuyNowMusicDialog = lazy(
    () =>
        import(
            /* webpackChunkName: "buy-now-music-dialog" */ 'components/dialogs/BuyNowMusicDialog'
        )
);

type Props = { children: ReactNode };

const BuyNowMusicProvider = ({ children }: Props) => {
    const [buyNowMusicData, setBuyNowMusicData] =
        useState<BuyNowMusicData | null>(null);

    const value = useMemo(
        () => ({
            buyNowMusicData,
            setBuyNowMusicData
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [buyNowMusicData]
    );

    const onClose = () => {
        setBuyNowMusicData(null);
    };

    return (
        <BuyNowContext.Provider value={value}>
            {children}
            {buyNowMusicData && (
                <Suspense fallback={null}>
                    <BuyNowMusicDialog
                        open
                        onClose={onClose}
                        album={buyNowMusicData.album}
                        tracks={buyNowMusicData.tracks}
                        artist={buyNowMusicData.artist}
                        currency={buyNowMusicData.currency}
                        id={buyNowMusicData.id}
                        price={buyNowMusicData.price}
                        title={buyNowMusicData.title}
                        type={buyNowMusicData.type}
                    />
                </Suspense>
            )}
        </BuyNowContext.Provider>
    );
};

export default BuyNowMusicProvider;
