import type { Playlist } from 'types';

export const checkIfPlaylistNameExists = (
    playlists: Playlist[],
    name: string
) => {
    return Boolean(
        playlists.find(
            playlist =>
                name.toLowerCase().trim() === playlist.name.toLowerCase().trim()
        )
    );
};
