import { compile } from 'path-to-regexp';
import type {
    UseMutationOptions,
    UseQueryOptions
} from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';
import type { AxiosResponseWithData } from 'overrides/axios';
import axios from 'overrides/axios';
import type {
    Album,
    Currency,
    PaginationMeta,
    PaginationParams,
    Track,
    AlbumParams
} from 'types';
import type { PaymentOptions } from 'enums/payment-options';
import type { FetchResponseError } from 'types/fetch';
import queryClient from 'shared/query-client';
import URIS from './uris.json';

export const albumsQueryKeys = {
    ALBUM_RECENTLY_ADDED: 'albums.recently.added',
    ALBUM_TRACKS: 'album.tracks',
    ALBUM: 'album',
    ALBUMS_PROMOTED_SLOTS: 'albums.promoted.slots',
    ALBUMS_PROMOTED: 'albums.promoted',
    ALBUMS: 'albums',
    DISCOVER: 'albums.discover'
};

const getArtistAlbumsUrl = compile<AlbumParams>(`${URIS.artists.albums.index}`);
const getAlbumTracksUrl = compile<AlbumParams>(`${URIS.artists.albums.tracks}`);

type AlbumPromotedRequest = {
    albumId: number;
    transactionId: string;
    payment: PaymentOptions;
    start: string;
    stop: string;
    amount: number;
    currency: Currency;
};

type AlbumPromotedResponse = {
    albumId: number;
    id: number;
    promotionPaymentId: number;
    start: string;
    stop: string;
};

export const useAlbumPromotionOrderMutation = (
    options?: UseMutationOptions<
        AlbumPromotedResponse,
        FetchResponseError,
        AlbumPromotedRequest
    >
) => {
    return useMutation({
        ...options,
        mutationFn: async (data: AlbumPromotedRequest) => {
            const response = await axios.post<AlbumPromotedResponse>(
                URIS.albums.promoted.index,
                data
            );

            return response.data;
        }
    });
};

export const useAlbumPromotionBookMutation = (
    options?: UseMutationOptions<
        AlbumPromotedResponse,
        FetchResponseError,
        AlbumPromotedRequest
    >
) => {
    return useMutation(async (data: AlbumPromotedRequest) => {
        const response = await axios.post<
            AlbumPromotedRequest,
            AxiosResponseWithData<AlbumPromotedResponse>
        >(URIS.orders.promotions, data);

        return response.data;
    }, options);
};

const getAlbumsPromoted = async () => {
    const response = await axios.get<Album[]>(URIS.albums.promoted.index, {
        params: {
            fields: ['artist', 'price'].join()
        }
    });

    return response.data;
};

export const useAlbumsPromotedQuery = () => {
    return useQuery<Album[], FetchResponseError>({
        queryKey: [albumsQueryKeys.ALBUMS_PROMOTED],
        queryFn: getAlbumsPromoted
    });
};

type Slot = {
    start: string;
    stop: string;
};

type AlbumsPromotedSlotsResponse = {
    slots: Slot[];
    limitPerDay: number;
    minOfDays: number;
    maxNumberOfWeeks: number;
    boundaryStartDate: string;
    boundaryStopDate: string;
};
const getAlbumsPromotedSlots = async () => {
    const response = await axios.get<AlbumsPromotedSlotsResponse>(
        URIS.albums.promoted.slots
    );

    return response.data;
};

export const useAlbumsPromotedSlotsQuery = () => {
    return useQuery<AlbumsPromotedSlotsResponse, FetchResponseError>({
        queryKey: [albumsQueryKeys.ALBUMS_PROMOTED_SLOTS],
        queryFn: getAlbumsPromotedSlots
    });
};

type UseAlbumQueryOptions = UseQueryOptions<Album, FetchResponseError> & {
    includeArtistAlbums?: boolean;
};
export const albumQuery = (
    { artistSlug, albumSlug }: AlbumParams,
    includeArtistAlbums = false
) => {
    const fields = ['genre', 'price', 'tracks.artists']
        .concat(includeArtistAlbums ? ['artist.albums.price'] : ['artist'])
        .join();
    const url = getArtistAlbumsUrl({
        artistSlug,
        albumSlug
    });

    return {
        queryKey: [albumsQueryKeys.ALBUM, { artistSlug, albumSlug, fields }],
        queryFn: async () => {
            const response = await axios.get<Album>(url, {
                params: {
                    fields
                }
            });

            // filter out current album from artist's album
            if (response.data.artist.albums) {
                response.data.artist.albums =
                    response.data.artist.albums.filter(
                        album => album.id !== response.data.id
                    );
            }

            return response.data;
        },
        staleTime: 5 * 60 * 1000 // 5 mins
    };
};

export const useAlbumQuery = (
    {
        artistSlug,
        albumSlug,
        includeArtistAlbums = false
    }: AlbumParams & { includeArtistAlbums?: boolean },
    options: UseAlbumQueryOptions
) => {
    return useQuery<Album, FetchResponseError>({
        ...options,
        ...albumQuery({ artistSlug, albumSlug }, includeArtistAlbums)
    });
};

export const useAlbumsQuery = ({ page, perPage }: PaginationParams) => {
    type Response = {
        data: Album[];
        meta: PaginationMeta;
    };

    return useQuery<Response, FetchResponseError>({
        queryKey: [albumsQueryKeys.ALBUMS, { page, perPage }],
        queryFn: async () => {
            const { data, meta } = await axios.get<Response, Response>(
                URIS.albums.index,
                {
                    params: {
                        fields: ['artist', 'owns', 'price'].join(),
                        page,
                        perPage
                    }
                }
            );

            return {
                data,
                meta
            };
        },
        keepPreviousData: true
    });
};

export const useRecentlyAddedQuery = ({ page, perPage }: PaginationParams) => {
    type Response = {
        data: Album[];
        meta: PaginationMeta;
    };

    return useQuery<Response, FetchResponseError>({
        queryKey: [albumsQueryKeys.ALBUM_RECENTLY_ADDED, { page, perPage }],
        queryFn: async () => {
            const { data, meta } = await axios.get<Response, Response>(
                URIS.albums.recentlyAdded,
                {
                    params: {
                        fields: ['artist'].join(),
                        page,
                        perPage
                    }
                }
            );

            return {
                data,
                meta
            };
        },
        keepPreviousData: true
    });
};

export const useDiscoverQuery = () => {
    return useQuery<Album[], FetchResponseError>({
        queryKey: [albumsQueryKeys.DISCOVER],
        queryFn: async () => {
            const response = await axios.get<Album[]>(URIS.albums.random, {
                params: {
                    fields: ['artist'].join()
                }
            });

            return response.data;
        }
    });
};

export const getAlbumTracks = ({ artistSlug, albumSlug }: AlbumParams) => {
    return () => {
        return queryClient.fetchQuery({
            queryKey: [albumsQueryKeys.ALBUM_TRACKS, { artistSlug, albumSlug }],
            queryFn: async () => {
                const response = await axios.get<Track[]>(
                    getAlbumTracksUrl({
                        artistSlug,
                        albumSlug
                    }),
                    {
                        params: {
                            fields: ['artists', 'artist', 'album.price'].join()
                        }
                    }
                );

                return response.data;
            }
        });
    };
};
