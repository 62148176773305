import type { Middleware } from 'redux';
import { bindActionCreators } from 'redux';
import type { Store, AppDispatch, RootState } from 'store';
import {
    FETCH_TOKEN_SUCCESS,
    LOAD,
    ON_ADD_TO_QUEUE,
    ON_CAST_CONNECT,
    ON_CAST_DISCONNECT,
    ON_NEXT,
    ON_PAUSE,
    ON_PAUSED,
    ON_PLAY,
    ON_PLAYER_READY,
    ON_PLAYING,
    ON_PREV,
    ON_REMOVE_FROM_QUEUE,
    ON_REPEAT,
    ON_SHUFFLE,
    ON_STOP,
    ON_VOLUME_CHANGE
} from 'actions/types';
import { getActionScope } from 'helpers/get-action-scope';
import { loadScript } from 'helpers/load-script';
import { getTrackAlbumArtistsText, findTrackIndex } from 'helpers/track';
import { isSamePlaylist } from 'helpers/is-same-playlist';
import { isCypress } from 'helpers/is-cypress';
import { resolveIsPlayerReady } from 'helpers/player';
import type Actions from 'actions';
import * as actions from 'actions';
import { albumsQueryKeys } from 'queries/albums';
import logger from 'utilities/logger';
import events, { queue } from 'overrides/events';
import queryClient from 'shared/query-client';
import CastPlayer from './cast-player';
import Player from './player';
import playerProxy from './player-proxy';

const CHROMECAST_SENDER_URL =
    '//www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1';

let remotePlayer: CastPlayer | null = null;
let pendingPlay = false;

const CAST_LOAD_TRIES = 2;

const CHROMECAST_WAIT_TIME = 30 * 1000;
let chromecastLoadTries = 0;
// const SKIP_TIME = 10; // Time to skip in seconds

const middleware: Middleware<{}, RootState, AppDispatch> = store => {
    const { dispatch, getState } = store;
    const actionCreators = bindActionCreators(actions, dispatch);

    const {
        onAutoplay,
        onLoading,
        onBufferChange,
        onCastConnect,
        onCastDisconnect,
        onCastDeviceNameChange,
        onCastStateChange,
        onError,
        onPaused,
        onPlaying,
        onPlaylistItem,
        onReady,
        onStop,
        onPrev,
        onNext,
        onPlay,
        onPause,
        snackbarOpen
    } = actionCreators;

    const playerOptions = {
        getState,
        onAutoplay,
        onPlaylistItem,
        onPlaying,
        onPaused,
        onStop,
        onLoading,
        onBufferChange,
        onNext,
        onPrev,
        onPause,
        onPlay,
        onCastConnect,
        onCastDisconnect,
        onCastDeviceNameChange,
        onCastStateChange,
        snackbarOpen,
        onBufferFull: () => {
            onBufferChange({ bufferPercent: 100 });
        },
        onError: (error: unknown) => {
            const track = playerProxy.getCurrentItem();

            if (track) {
                const message = `${track.id} - ${
                    track.title
                } by ${getTrackAlbumArtistsText(track)}: ${JSON.stringify(
                    error
                )}`;

                onError({
                    message
                });
            }
        }
    };

    const localPlayer = new Player(playerOptions);

    playerProxy.setStore(store as Store);
    playerProxy.setPlayer(localPlayer, true);

    const onWindowLoad = () => {
        resolveIsPlayerReady?.(true);

        onReady();
    };

    events.once('chromecast:load', function loadChromecast() {
        const onGCastApiAvailable = (isAvailable: boolean) => {
            if (isAvailable && chromecastLoadTries < CAST_LOAD_TRIES) {
                if (!(window.cast && cast.framework && chrome.cast)) {
                    setTimeout(
                        onGCastApiAvailable,
                        CHROMECAST_WAIT_TIME / 10,
                        isAvailable
                    );

                    return;
                }

                remotePlayer = new CastPlayer(playerOptions);
            }

            chromecastLoadTries++;
        };

        setTimeout(() => {
            window.__onGCastApiAvailable = onGCastApiAvailable;

            if (!isCypress()) {
                const scripts = [];

                if (window.chrome) {
                    scripts.push(
                        loadScript(CHROMECAST_SENDER_URL, 'chromecast-sender')
                    );
                }

                Promise.all(scripts).catch(logger.error);
            }
        }, CHROMECAST_WAIT_TIME);
    });

    window.addEventListener('load', onWindowLoad);

    return next =>
        async function middleware(action: Actions) {
            const isPlayerScope = getActionScope(action.type) === 'player';
            const { player } = getState();

            if (!isPlayerScope) {
                // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
                switch (action.type) {
                    case ON_STOP:
                    case ON_PLAYING:
                    case ON_PAUSED: {
                        const track = playerProxy.getCurrentItem();

                        if (!track) {
                            logger.warn(
                                `track: ${JSON.stringify(track)} is falsy`
                            );
                        }

                        break;
                    }
                    case ON_CAST_CONNECT: {
                        if (remotePlayer) {
                            const isResumed = remotePlayer.getIsResumed();

                            playerProxy.setPlayer(remotePlayer, isResumed);
                        }

                        break;
                    }
                    case ON_CAST_DISCONNECT: {
                        playerProxy.setPlayer(localPlayer);
                        break;
                    }
                    case FETCH_TOKEN_SUCCESS: {
                        const {
                            player: {
                                params: { artistSlug, albumSlug }
                            }
                        } = getState();

                        if (albumSlug && artistSlug) {
                            await queryClient.refetchQueries(
                                [
                                    albumsQueryKeys.ALBUM,
                                    { artistSlug, albumSlug }
                                ],
                                {
                                    exact: true
                                }
                            );
                        }

                        break;
                    }
                }

                return next(action);
            }

            const result = next(action);

            // logger.info(
            //     `isLoaded=${isLoaded}, readyToSetup=${readyToSetup}, isReady=${isReady}`
            // );

            if (!player.isReady) {
                if (action.type !== ON_PLAYER_READY) {
                    pendingPlay = true;

                    logger.warn(
                        'player not initialized:',
                        typeof localPlayer,
                        typeof remotePlayer
                    );
                }

                return next(action);
            }

            // logger.debug(
            //     `@player.middleware:${action.type}:${JSON.stringify(
            //         action.payload
            //     )}`
            // );

            switch (action.type) {
                case LOAD:
                    {
                        const { currentItemId } = player;
                        const { trackId, playlist, autoPlay, params } =
                            action.payload;

                        const trackIndex = findTrackIndex(
                            playlist.items,
                            trackId
                        );
                        const hasPlaylistChanged = !isSamePlaylist(
                            player.params,
                            params
                        );
                        const hasTrackChanged = Boolean(
                            trackId !== currentItemId
                        );
                        const isPlayerEmpty =
                            playerProxy.getPlaylistItems().length === 0;

                        if (
                            (hasPlaylistChanged && playlist.items?.length) ||
                            isPlayerEmpty
                        ) {
                            const index = trackIndex === -1 ? 0 : trackIndex;
                            const item = playlist.items[index];

                            playerProxy.load({
                                currentItemIndex: index,
                                currentItemId: item.id,
                                tracks: playlist.items,
                                id: playlist.id!,
                                skipOverride: false
                            });
                        }

                        if (autoPlay || pendingPlay) {
                            pendingPlay = false;

                            if (
                                player.status === 'playing' &&
                                !hasTrackChanged
                            ) {
                                playerProxy.pause();
                            } else {
                                playerProxy.play(trackId);
                            }
                        }

                        queue('chromecast:load');
                    }
                    break;
                case ON_PLAY:
                    playerProxy.play();
                    break;
                case ON_PAUSE:
                    playerProxy.pause();
                    break;
                case ON_NEXT:
                    playerProxy.next();
                    break;
                case ON_PREV:
                    playerProxy.prev();
                    break;
                case ON_REPEAT: {
                    const { player } = getState();

                    playerProxy.repeat(player.repeat);
                    break;
                }
                case ON_SHUFFLE: {
                    playerProxy.shuffle();
                    break;
                }
                case ON_VOLUME_CHANGE: {
                    const { volume } = action.payload;

                    playerProxy.volume(volume);
                    break;
                }
                case ON_ADD_TO_QUEUE: {
                    const { tracks } = action.payload;

                    playerProxy.add(tracks);

                    break;
                }
                case ON_REMOVE_FROM_QUEUE: {
                    const { trackId } = action.payload;

                    playerProxy.remove(trackId);

                    break;
                }
                default:
            }

            return result;
        };
};

export default middleware;
