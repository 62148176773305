import './wdyr';
import './polyfills';
import 'globalthis/auto';
// eslint-disable-next-line import/no-unresolved
import /* webpackPreload: true */ './css/style.css';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import classnames from 'classnames';
import whenDomReady from 'when-dom-ready';
import { HelmetProvider } from 'react-helmet-async';
import type { Metric } from 'web-vitals';
import Cookies from 'js-cookie';
// @ts-ignore: thirdparty library
import registerStore from 'cypress-redux/registerStore';
import { onAppUpdate, onPageReady, onConnectionChange } from 'actions';
import App from 'App';
import * as serviceWorkerRegistration from 'serviceWorkerRegistration';
import store from 'store';
import { isMobile } from 'helpers/is-mobile';
import { isDesktop } from 'helpers/is-desktop';
import { isTablet } from 'helpers/is-tablet';
import { isCypress } from 'helpers/is-cypress';
import { getAppRoot, getAppInfo } from 'helpers/app';
import queryClient from 'shared/query-client';
import { getCookieOptions } from 'helpers/cookie';
import bowser from 'overrides/bowser';
import logger from 'utilities/logger';
import {
    logAnalyticsCustomEvent,
    logAnalyticsEvent,
    setAnalyticsUserProperties
} from 'overrides/firebase.analytics';
import { isRemoteConfigReady } from 'overrides/firebase.remote-config';
import { onFirebaseMessage } from 'overrides/firebase.messaging';
import { updateOrCreateSession } from 'queries/global';
import type { CookieKeys } from 'overrides/js-cookie';
import 'overrides/sentry';
import events, { queue } from 'overrides/events';
import { getPersistOptions } from 'queries/persisters';
import type { Connection } from 'types/store';
import reportWebVitals from './reportWebVitals';

const { version, buildNumber } = getAppInfo();

window.onerror = function onerror(
    message,
    url,
    lineNumber,
    columnNumber,
    error
) {
    // @ts-ignore
    const string = message.toLowerCase();

    const isScriptError = string.indexOf('script error') > -1;

    if (isScriptError && error) {
        logger.error(`Script Error: ${JSON.stringify(error)}`);
    } else {
        const description = [
            'Message: ' + message,
            `URL: ${url}`,
            'Line: ' + lineNumber,
            'Column: ' + columnNumber,
            'Error object: ' + JSON.stringify(error)
        ].join(' - ');

        try {
            logAnalyticsEvent('exception', {
                description: description,
                fatal: 'true'
            });
        } catch (e) {
            logger.error(e);
        }
    }

    return false;
};

registerStore(store);

if (isCypress()) {
    globalThis.parent?.Cypress?.on('window:load', () => {
        window.store = store;
        logger.info('Cypress:store:added');
    });
}

const root = getAppRoot();
const reactRoot = createRoot(root);
const body = document.body;
const originalClassName = body.className;

const applyClassName = () => {
    const isSmall = isMobile();
    const os = bowser.getOSName(true);

    const className = classnames({
        // [`${parser.getPlatformType()}`]: true,
        desktop: isDesktop(),
        tablet: isTablet(),
        mobile: isSmall,
        small: isSmall,
        large: !isSmall,
        [`${os !== 'undefined' ? os : 'unknown-os'}`]: true
    });

    if (body) {
        body.className = `${originalClassName} ${className}`.trim();
    }
};

const persistOptions = getPersistOptions();

applyClassName();

// a11y help
// if (process.env.NODE_ENV !== 'production') {
//     import('react-axe').then(({ default: axe }) => {
//         return axe(
//             React,
//             ReactDOM,
//             1000,
//             {
//                 rules: [
//                     {
//                         id: 'meta-viewport',
//                         enabled: false
//                     },
//                     {
//                         id: 'meta-viewport-large',
//                         enabled: false
//                     }
//                 ]
//             },
//             {
//                 include: [['#root']]
//             }
//         );
//     });
// }

isRemoteConfigReady.finally(() => {
    reactRoot.render(
        <StrictMode>
            <HelmetProvider>
                <Provider store={store}>
                    <PersistQueryClientProvider
                        client={queryClient}
                        persistOptions={persistOptions}
                    >
                        <App />
                    </PersistQueryClientProvider>
                </Provider>
            </HelmetProvider>
        </StrictMode>
    );
});

events.once('service-worker:register', function registerServiceWorker() {
    const onServiceWorkerUpdate = (registration: ServiceWorkerRegistration) => {
        scheduler.postTask(
            () => {
                store.dispatch(onAppUpdate(registration));
            },
            { delay: 10000 }
        );
    };

    serviceWorkerRegistration.register({
        onUpdate: onServiceWorkerUpdate
    });
});

const onResize = () => {
    if (bowser.is('mobile')) {
        const height = Math.min(
            document.documentElement.clientHeight,
            window.screen.height,
            window.innerHeight
        );

        if (root) {
            root.setAttribute('style', `height: ${height}px`);
        }

        if (body) {
            body.setAttribute('style', `height: ${height}px`);
        }
    } else {
        if (root) {
            root.removeAttribute('style');
        }

        if (body) {
            body.removeAttribute('style');
        }
    }

    applyClassName();
};

const onNetworkChange = (e: Event) => {
    if (navigator.onLine) {
        // update right away, if the phone came online
        document.body.classList.remove('offline');
        store.dispatch(onConnectionChange(e.type as Connection));
    } else {
        // else delay when it goes offline to handle a flaky network connection
        setTimeout(() => {
            let type: Connection = 'offline';

            if (navigator.onLine) {
                type = 'online';
                document.body.classList.remove('offline');
            } else {
                document.body.classList.add('offline');
            }

            store.dispatch(onConnectionChange(type));
        }, 1000);
    }
};

whenDomReady().then(onResize).catch(logger.error);
['resize', 'orientationchange'].forEach(event => {
    window.addEventListener(event, onResize);
});
['online', 'offline'].forEach(e => {
    window.addEventListener(e, onNetworkChange);
});

/*
  temporary disabling prompt cos of issues with device killing app in background while playing music
*/
window.addEventListener(
    'beforeinstallprompt',
    async function onBeforeInstallPrompt(e: { preventDefault: () => void }) {
        e.preventDefault();

        let prompt = true;

        if ('getInstalledRelatedApps' in navigator) {
            // @ts-ignore: browser type missing
            const relatedApps = await navigator.getInstalledRelatedApps();

            prompt = relatedApps.length > 0;
            // relatedApps.forEach(app => {
            //     logger.info(app.id, app.platform, app.url);
            // });
        }

        return prompt;
    }
);

// eslint-disable-next-line func-style
function sendToAnalytics({ id, name, delta }: Partial<Metric>) {
    // Assumes the global `gtag()` function exists, see:
    // https://developers.google.com/analytics/devguides/collection/gtagjs

    logAnalyticsCustomEvent(name!, {
        // eslint-disable-next-line camelcase
        event_category: 'Web Vitals',
        // Google Analytics metrics must be integers, so the value is rounded.
        // For CLS the value is first multiplied by 1000 for greater precision
        // (note: increase the multiplier for greater precision if needed).
        value: Math.round(name! === 'CLS' ? delta! * 1000 : delta!),
        // The `id` value will be unique to the current page load. When sending
        // multiple values from the same page (e.g. for CLS), Google Analytics can
        // compute a total by grouping on this ID (note: requires `eventLabel` to
        // be a dimension in your report).
        // eslint-disable-next-line camelcase
        event_label: id!,
        // Use a non-interaction event to avoid affecting bounce rate.
        // eslint-disable-next-line camelcase
        non_interaction: true
    });
}

window.addEventListener('load', function windowLoad() {
    scheduler.postTask(() => {
        // [2022-10-25] @cepoh - remove later, this is to migrate from old cookie domain to new one
        if (!Cookies.get('migrated')) {
            const cookies = Cookies.get();
            const allowList: CookieKeys[] = [
                'username',
                'referralCode',
                'shouldAutoLogin',
                'rememberMe'
            ];

            Object.keys(cookies).forEach(name => {
                if (
                    allowList.includes(name as CookieKeys) ||
                    name === 'referral_code'
                ) {
                    const value = cookies[name];

                    Cookies.remove(name);

                    Cookies.set(name, value, getCookieOptions());
                }
            });

            Cookies.set('migrated', 'true');
        }
    });

    updateOrCreateSession().catch(logger.error);
});

events.once('page:ready', function pageReady() {
    scheduler.postTask(() => {
        store.dispatch(onPageReady());
    });

    scheduler.postTask(() => {
        onFirebaseMessage(payload => {
            logger.log('Message received. ', payload);
        }).catch(logger.error);
    });

    scheduler.postTask(() => {
        // send analytics event
        setAnalyticsUserProperties({
            // eslint-disable-next-line camelcase
            app_version: version,
            // eslint-disable-next-line camelcase
            app_build_number: buildNumber
        });
    });

    scheduler.postTask(
        () => {
            // If you want to start measuring performance in your app, pass a function
            // to log results (for example: reportWebVitals(logger.log))
            // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
            reportWebVitals(sendToAnalytics);
        },
        {
            priority: 'background'
        }
    );

    scheduler.postTask(
        () => {
            queue('service-worker:register', 5000);
        },
        {
            priority: 'background'
        }
    );
});

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.addEventListener(
        'message',
        function onmessage(event) {
            logger.log('Received a message from service worker: ', event.data);
        }
    );
}
