import type { MouseEvent, ReactNode, SyntheticEvent } from 'react';
import {
    Suspense,
    useRef,
    useEffect,
    useMemo,
    useCallback,
    useState,
    lazy
} from 'react';
import classnames from 'classnames';
import LinearProgress from '@mui/material/LinearProgress';
import Snackbar from '@mui/material/Snackbar';
import { Helmet } from 'react-helmet-async';
import snakeCase from 'lodash/snakeCase';
import Container from '@mui/material/Container';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import Link from '@mui/material/Link';
import { useDispatch, useSelector } from 'react-redux';
import {
    useNavigate,
    useLocation,
    useSearchParams,
    useNavigation
} from 'react-router-dom';
import useComponentSize from '@rehooks/component-size';
import Spinner from 'components/Spinner';
import Empty from 'components/Empty';
import { TOOLTIP_CLASSNAME } from 'shared/constants/tooltip';
import { getAppName } from 'helpers/app';
import { getUriRoute } from 'helpers/uri';
import { isVerified } from 'helpers/is-verified';
import { toThemeType } from 'helpers/to-theme-type';
import ShellContext from 'contexts/shell';
import ROUTES from 'shared/routes';
import config from 'config';
import type { PageRouteKeys } from 'shared/page-routes';
import PageRoutes from 'shared/page-routes';
import { usePreviousValue } from 'hooks/use-previous-value';
import { useUser } from 'hooks/use-user';
import { useTheme } from 'hooks/use-theme';
import { getPlayer, getShell } from 'selectors';
import type { AppDispatch } from 'store';
import logger from 'utilities/logger';
import Cookies from 'overrides/js-cookie';
import {
    logAnalyticsCustomEvent,
    logAnalyticsEvent
} from 'overrides/firebase.analytics';
import {
    backButtonHide,
    snackbarClose,
    tabChange,
    onPushSubscription,
    snackbarOpen,
    alertClose
} from 'actions';
import { getRemoteConfigBooleanValue } from 'overrides/firebase.remote-config';
import { getPushToken, deletePushToken } from 'queries/global';
import {
    subscribeToPushNotification,
    isPushNotificationPermissionGranted
} from 'helpers/push-notification';
import type { LocationState } from 'types';
import { queue } from 'overrides/events';
import NavigationBar from './components/navigation-bar';
import PlayerControl from './components/player-control';
import Alert from './components/Alert';
import AddToPlaylistProvider from './providers/AddToPlaylistProvider';
import BuyNowMusicProvider from './providers/BuyNowMusicProvider';
import { Pane as SearchPane } from './components/search';
import ErrorBoundary from './components/ErrorBoundary';

const UnsupportedBrowserBanner = lazy(
    () =>
        import(
            /* webpackChunkName: "unsupported-browser-banner" */ 'components/UnsupportedBrowserBanner'
        )
);

const classPrefix = 'page';

const Shell = ({ children }: { children: ReactNode }) => {
    const dispatch = useDispatch<AppDispatch>();
    const location = useLocation();
    const navigate = useNavigate();
    const navigation = useNavigation();
    const user = useUser();
    const player = useSelector(getPlayer);
    const { isLoading, isPushEnabled, isPageReady, ...props } =
        useSelector(getShell);
    const [keyword, setKeyword] = useState('');
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const prevKeyword = usePreviousValue<string>(keyword);
    const $pageContent = useRef<HTMLDivElement | null>(null);
    const theme = useTheme();
    const size = useComponentSize($pageContent);
    const isLoggedIn = Boolean(user.id);
    const wasLoggedIn = usePreviousValue<boolean>(Boolean(user.id));
    const [searchParams] = useSearchParams();
    const referralCode = searchParams.get('referral_code');
    const shareId = searchParams.get('shareId');
    const isCacheMusicEnabled = getRemoteConfigBooleanValue('CacheMusic');
    const isPushNotificationEnabled =
        getRemoteConfigBooleanValue('PushNotification');
    const isPushNotificationDismissed =
        Cookies.get('pushNotificationAlertDismissed') === 'true';
    const isPlaying = player.status !== 'idle';

    const value = useMemo(
        () => ({
            user,
            size
        }),
        [user, size]
    );

    useEffect(
        function onColorSchemeChange() {
            const value = toThemeType(theme.colorScheme);

            document.documentElement.dataset.theme = value;
        },
        [theme.colorScheme]
    );

    const getPageData = (pathname: string) => {
        const pageKey = getUriRoute(pathname);
        const pageData = {
            ...(PageRoutes[pageKey as PageRouteKeys] || {})
        };

        if (
            !pageData.className &&
            pageData.title &&
            typeof pageData.title === 'string'
        ) {
            pageData.className = `page-${snakeCase(pageData.title)}`;
        }

        const isChromeless = pageData.hideChrome === true;
        const tabs = pageData.tabs || [];

        if (
            typeof pageData.maxWidth === 'undefined' &&
            tabs.length === 0 &&
            !isChromeless
        ) {
            pageData.maxWidth = 'md';
        }

        return pageData;
    };

    const pageProps = useMemo(() => {
        return getPageData(location.pathname).props || {};
    }, [location.pathname]);

    const getTitle = (pathname: string, skipPrefix = false) => {
        let pageTitle = getPageData(pathname).title ?? 'Not Found';

        if (typeof pageTitle === 'function') {
            pageTitle = pageTitle(props);
        }

        return `${pageTitle}${skipPrefix ? '' : ` | ${getAppName()}`}`;
    };

    const getDescription = (pathname: string) => {
        return getPageData(pathname).description || '';
    };

    const getTabs = (pathname: string) => {
        return getPageData(pathname).tabs || [];
    };

    useEffect(function onMount() {
        const title = getTitle(location.pathname, true);

        trackScreen(location.pathname);

        if (title === 'Not Found') {
            logAnalyticsCustomEvent('route_not_found', {
                location
            });
        }

        queue('page:ready');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(
        function onLocationChange() {
            const { pathname, key } = location;

            trackScreen(pathname);

            if (
                key &&
                !config.IS_PROD &&
                [document.title, getTitle(pathname)].includes(
                    `Not Found | ${getAppName()}`
                )
            ) {
                const message = `Page title missing for ${pathname}, please add one`;

                logger.error(message);
            }

            if (isSearchOpen) {
                setIsSearchOpen(false);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [location]
    );

    useEffect(
        function onLocationHashChange() {
            const { tabIndex } = props;
            const { pathname, hash } = location;
            const tabs = getTabs(pathname);

            const newTabIndex = tabs.findIndex(
                tab => tab.label.toLowerCase() === hash.substr(1).toLowerCase()
            );

            if (newTabIndex !== -1 && tabIndex !== newTabIndex) {
                dispatch(tabChange(newTabIndex));
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [location.hash]
    );

    useEffect(
        function onKeywordChange() {
            const initialSearch = prevKeyword === '' && keyword;

            if (initialSearch) {
                $pageContent?.current?.scroll(0, 0);
            }

            if (keyword && !isSearchOpen) {
                setIsSearchOpen(true);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [keyword]
    );

    useEffect(
        function onIsSearchOpenChange() {
            if (!isSearchOpen && keyword !== '') {
                setKeyword('');
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isSearchOpen]
    );

    useEffect(
        function onTabIndexChange() {
            const { tabIndex } = props;
            const tabs = getTabs(location.pathname);

            if (tabs.length > 0 && tabIndex !== -1) {
                const tab = tabs[tabIndex];

                if (tab) {
                    const hashFragment = tab.label?.toLowerCase();

                    navigate(`#${hashFragment}`);
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.tabIndex]
    );

    useEffect(
        function onIsLoggedInChange() {
            if (!isPushNotificationEnabled) {
                return;
            }

            if (!isLoggedIn || !('serviceWorker' in navigator)) {
                return;
            }

            let isPushSubscriptionEnabled = false;

            navigator.serviceWorker.ready
                .then(async serviceWorkerRegistration => {
                    try {
                        const subscription =
                            await serviceWorkerRegistration.pushManager.getSubscription();
                        const currentToken = await getPushToken();

                        if (subscription) {
                            if (!currentToken) {
                                await subscribeToPushNotification();
                            }

                            isPushSubscriptionEnabled = true;
                        } else if (currentToken) {
                            await deletePushToken();
                        }
                    } finally {
                        if (isPushSubscriptionEnabled !== isPushEnabled) {
                            dispatch(
                                onPushSubscription(isPushSubscriptionEnabled)
                            );
                        }
                    }
                })
                .catch(e => {
                    logger.error(e);
                    dispatch(
                        snackbarOpen(
                            "Can't enable notification, please try again"
                        )
                    );
                });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isLoggedIn]
    );

    useEffect(
        function onIsLoggedInChange() {
            if (isLoggedIn === wasLoggedIn || !isLoggedIn) {
                return;
            }

            if (location.pathname === ROUTES.LOGIN) {
                const route =
                    (location.state as LocationState)?.from || ROUTES.HOME;

                navigate(route);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isLoggedIn]
    );

    useEffect(function onMount() {
        if (referralCode) {
            Cookies.set('referralCode', referralCode);
        }

        if (shareId) {
            Cookies.set('shareId', shareId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const trackScreen = (pathname: string) => {
        const title = getTitle(pathname, true);

        logAnalyticsEvent('page_view', {
            // eslint-disable-next-line camelcase
            page_title: title,
            // eslint-disable-next-line camelcase
            page_path: pathname
        });
    };

    const onMouseOver = (e: MouseEvent<HTMLElement>) => {
        const { target } = e;

        // No bind, because of no "this"
        // @ts-ignore: more research is needed
        if (!target.matches(`.${TOOLTIP_CLASSNAME}`) || target.title) {
            return;
        }

        // @ts-ignore: more research is needed
        const overflowed = target.scrollWidth > target.clientWidth;

        // @ts-ignore: more research is needed
        if (overflowed && target.innerText) {
            // @ts-ignore: more research is needed
            target.title = target.innerText;
        }
    };

    const onBackButtonClick = useCallback(
        (e: MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();

            dispatch(backButtonHide());
            let previousUrl = {
                hostname: window.location.hostname
            };

            if (document.referrer) {
                previousUrl = new URL(document.referrer);
            }

            const shouldGoBack =
                previousUrl.hostname === window.location.hostname;

            if (shouldGoBack) {
                navigate(-1);
            } else {
                navigate(ROUTES.HOME, { replace: true });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const onSearchItemClick = useCallback((url: string) => {
        navigate(url);
        setIsSearchOpen(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const tabs = useMemo(() => {
        return getTabs(location.pathname);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const onSearch = useCallback((query: string) => {
        setKeyword(query);
    }, []);

    const onSearchClose = useCallback(() => {
        setIsSearchOpen(false);
    }, []);

    const onSearchOpen = useCallback(() => {
        setIsSearchOpen(true);
    }, []);

    const onPushSubscriptionDismiss = (e: SyntheticEvent<Element>) => {
        e.preventDefault();

        Cookies.set('pushNotificationAlertDismissed', 'true', {
            expires: 30
        });
        dispatch(onPushSubscription(isPushNotificationPermissionGranted()));
    };

    const onPushSubscriptionEnable = async (e: SyntheticEvent<Element>) => {
        e.preventDefault();

        try {
            await subscribeToPushNotification();

            dispatch(onPushSubscription(isPushNotificationPermissionGranted()));
        } catch (e) {
            logger.error(e);
        }
    };

    const renderChildren = () => {
        if (isOffline) {
            return (
                <Empty heading='' body="You're offline">
                    <CloudOffIcon
                        style={{
                            fontSize: 50
                        }}
                    />
                </Empty>
            );
        }

        if (isSearchOpen) {
            return null;
        }

        if (navigation.state === 'loading') {
            return <Spinner />;
        }

        return children;
    };

    const { snackbar, tabIndex, connection, alert } = props;

    const pageData = useMemo(
        () => getPageData(location.pathname),
        [location.pathname]
    );
    const isChromeless = pageData.hideChrome === true;
    const hidePlayerControl =
        pageData.hidePlayerControl === true || isChromeless;
    const showNavigation = pageData.hideNavigation !== true && !isChromeless;
    const isOffline = connection === 'offline';

    const wrapperClass = classnames(`${classPrefix}-content-wrapper`, {
        [`${classPrefix}-loading`]: Boolean(isLoading || isOffline),
        flex: true
    });
    const contentClass = classnames(
        `${classPrefix}-content flex flex-elastic`,
        {
            'player-visible': !hidePlayerControl,
            'no-navigation': !showNavigation,
            [`${pageData.className}`]: !!pageData.className
        }
    );
    const contentChildClass = classnames(
        `${classPrefix}-content-child flex flex-grow`,
        {
            'flex-justify-center': !!pageData.alignCenter
        }
    );

    return (
        <ShellContext.Provider value={value}>
            <AddToPlaylistProvider>
                <BuyNowMusicProvider>
                    <div className={wrapperClass}>
                        <Helmet>
                            <title>{getTitle(location.pathname)}</title>
                            <meta
                                name='description'
                                content={`${getDescription(location.pathname)}`}
                            />
                        </Helmet>
                        {showNavigation && (
                            <NavigationBar
                                {...pageProps}
                                isPageReady={isPageReady}
                                onBackButtonClick={onBackButtonClick}
                                tabIndex={tabIndex}
                                tabs={tabs}
                                title={getTitle(location.pathname, true)}
                                isSearchOpen={isSearchOpen}
                                onSearch={onSearch}
                                onSearchClose={onSearchClose}
                                onSearchOpen={onSearchOpen}
                            />
                        )}
                        {isLoading && <LinearProgress />}
                        <main
                            data-test-id={`${classPrefix}-content`}
                            role='main'
                            id={`${classPrefix}-content`}
                            className={contentClass}
                            onMouseOver={onMouseOver}
                            ref={$pageContent}
                        >
                            {!isChromeless && (
                                <Suspense fallback={null}>
                                    <UnsupportedBrowserBanner />
                                </Suspense>
                            )}
                            {alert.show && (
                                <Alert
                                    action={alert.options.action}
                                    title={alert.options.title}
                                    severity={alert.options.severity}
                                    icon={alert.options.icon}
                                    onClose={() => {
                                        setTimeout(() => {
                                            dispatch(alertClose());
                                        }, 5);
                                    }}
                                    show
                                >
                                    {alert.message}
                                </Alert>
                            )}
                            <Alert
                                action={{ to: ROUTES.LOGIN, text: 'OK' }}
                                show={
                                    isCacheMusicEnabled &&
                                    !isChromeless &&
                                    !isLoggedIn &&
                                    isPlaying &&
                                    !config.IS_PROD
                                }
                            >
                                To use less data, sign in
                            </Alert>
                            <Alert
                                action={{
                                    to: ROUTES.ARTIST_VERIFICATION,
                                    text: 'Verify'
                                }}
                                show={
                                    isLoggedIn &&
                                    user.type === 'artist' &&
                                    !isVerified(user) &&
                                    location.pathname !==
                                        ROUTES.ARTIST_VERIFICATION
                                }
                            >
                                To manage your content, please verify your
                                account
                            </Alert>
                            {isPushNotificationEnabled &&
                                !isPushNotificationDismissed && (
                                    <Alert
                                        onClose={onPushSubscriptionDismiss}
                                        show={isLoggedIn && !isPushEnabled}
                                    >
                                        {`${getAppName()} needs your permission to `}
                                        <Link
                                            href='#'
                                            color='primary'
                                            onClick={onPushSubscriptionEnable}
                                        >
                                            enable notifications
                                        </Link>
                                    </Alert>
                                )}
                            <Alert
                                action={{
                                    to: ROUTES.ARTIST_VERIFICATION,
                                    text: 'Contract'
                                }}
                                show={
                                    isLoggedIn &&
                                    user.type === 'artist' &&
                                    !!user.artist &&
                                    !user.artist.contract?.documentId &&
                                    location.pathname === ROUTES.ARTIST_SALES
                                }
                            >
                                Please click this link for music sales contract
                                signing.
                            </Alert>

                            <Container
                                disableGutters
                                className={contentChildClass}
                                maxWidth={pageData.maxWidth}
                                sx={{
                                    display: 'flex',
                                    position: 'relative'
                                }}
                            >
                                <ErrorBoundary location={location}>
                                    {isSearchOpen && (
                                        <Suspense fallback={<Spinner />}>
                                            <SearchPane
                                                onItemClick={onSearchItemClick}
                                                keyword={keyword}
                                            />
                                        </Suspense>
                                    )}
                                    <Suspense fallback={<Spinner />}>
                                        {renderChildren()}
                                    </Suspense>
                                </ErrorBoundary>
                            </Container>

                            <Snackbar
                                data-test-id='snackbar'
                                className='snackbar'
                                action={snackbar.options.action}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center'
                                }}
                                autoHideDuration={
                                    snackbar.options.persist
                                        ? null
                                        : snackbar.options.autoHideDuration ||
                                          5000
                                }
                                open={snackbar.isOpen}
                                onClose={(e, reason) => {
                                    if (!snackbar.options.persist) {
                                        dispatch(snackbarClose());
                                    }
                                }}
                                ContentProps={{
                                    'aria-describedby': 'snackbar-message-id'
                                }}
                                message={
                                    <span
                                        id='snackbar-message-id'
                                        data-test='snackbar-message-id'
                                        style={{ whiteSpace: 'pre-wrap' }}
                                    >
                                        {snackbar.message}
                                    </span>
                                }
                            />
                        </main>
                        {!hidePlayerControl && (
                            <Suspense fallback={null}>
                                <PlayerControl />
                            </Suspense>
                        )}
                        {/* <Footer {...childProps} /> */}
                    </div>
                </BuyNowMusicProvider>
            </AddToPlaylistProvider>
        </ShellContext.Provider>
    );
};

export default Shell;
