import { useEffect, useState } from 'react';
import getByKey from 'lodash/get';
import setByKey from 'lodash/set';
import merge from 'lodash/merge';
import type {
    AccountPreferences,
    DevicePreferences,
    Preferences,
    PreferenceType,
    PreferencesPaths,
    AccountPreferencesPaths,
    DevicePreferencesPaths
} from 'types';
import {
    useUserPreferencesMutation,
    useUserPreferencesQuery
} from 'queries/users';
import logger from 'utilities/logger';
import { useLocalStorage } from './use-local-storage';
import { useUser } from './use-user';

const VERSION = '0.0.1';

const combinePreferences = (
    device?: DevicePreferences,
    account?: AccountPreferences
): Preferences => {
    return merge({}, account, device);
};

const accountPreferencesKeys: AccountPreferencesPaths[] = ['player.repeat'];
const devicePreferencesKeys: DevicePreferencesPaths[] = [
    'shell.theme',
    'player.volume',
    'player.autoplay'
];
const getPreferenceValuesFor = <T>(
    preferences: Preferences,
    type: PreferenceType
): T => {
    const result: Partial<T> = {};

    const keys =
        type === 'account' ? accountPreferencesKeys : devicePreferencesKeys;

    keys.forEach(key => {
        const values = getByKey(preferences, key);

        setByKey(result, key, values);
    });

    return result as T;
};

export const usePreferences = () => {
    const user = useUser();
    const { data: accountPreferences } = useUserPreferencesQuery();
    const [devicePreferences, setDevicePreferences] = useLocalStorage<
        DevicePreferences | undefined
    >(`preferences.${user.id ?? 'guest'}`, undefined, VERSION);
    const userPreferencesMutate = useUserPreferencesMutation();
    const [preferences, setPreferences] = useState<Preferences>(
        combinePreferences(devicePreferences, accountPreferences)
    );
    const isLoggedIn = Boolean(user.id);

    useEffect(() => {
        setPreferences(
            combinePreferences(devicePreferences, accountPreferences)
        );
    }, [accountPreferences, devicePreferences]);

    const get = <T>(key: PreferencesPaths, defaultValue: T) => {
        return getByKey(preferences, key, defaultValue) as T;
    };

    const set = async <T>(key: PreferencesPaths, value: T) => {
        const newPreferences = merge({}, preferences, setByKey({}, key, value));

        setPreferences(newPreferences);

        const isAccountKey = accountPreferencesKeys.includes(
            key as AccountPreferencesPaths
        );
        const isDeviceKey = devicePreferencesKeys.includes(
            key as DevicePreferencesPaths
        );

        if (isLoggedIn && isAccountKey) {
            // save to server
            const accountPreferences =
                getPreferenceValuesFor<AccountPreferences>(
                    newPreferences,
                    'account'
                );

            await userPreferencesMutate.mutateAsync(accountPreferences);
        }

        if (isDeviceKey && !(isLoggedIn && isAccountKey)) {
            // save to local storage
            const devicePreferences = getPreferenceValuesFor<DevicePreferences>(
                newPreferences,
                'device'
            );

            setDevicePreferences(devicePreferences);
        }

        if (!(isAccountKey || isDeviceKey)) {
            logger.warn(
                `No preference found for key: ${key} - isAccountKey=${isAccountKey}, isDeviceKey=${isDeviceKey} `
            );
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return [get, set] as [
        <T>(key: PreferencesPaths, defaultValue: T) => T,
        <T>(key: PreferencesPaths, value: T) => void
    ];
};
