import type { ReactNode } from 'react';
import { useState, useMemo, Suspense, lazy } from 'react';
import { useDispatch } from 'react-redux';
import AddToPlaylistContext from 'contexts/add-to-playlist';
import type { Playlist, Track, TrackId } from 'types';
import {
    useUserPlaylistCreateMutation,
    useUserPlaylistsQuery,
    useUserPlaylistUpdateMutation
} from 'queries/users';
import { snackbarOpen } from 'actions';
import type { AppDispatch } from 'store';
import logger from 'utilities/logger';
import { checkIfPlaylistNameExists } from 'helpers/playlist';

const AddToPlaylistDialog = lazy(
    () =>
        import(
            /* webpackChunkName: "add-to-playlist-dialog" */ 'components/dialogs/AddToPlaylistDialog'
        )
);

const AddToPlaylistProvider = ({ children }: { children: ReactNode }) => {
    const dispatch = useDispatch<AppDispatch>();
    const [tracks, setTracks] = useState<Track[]>([]);
    const { data: playlists = [] } = useUserPlaylistsQuery();
    const userPlaylistCreateMutation = useUserPlaylistCreateMutation();
    const userPlaylistUpdateMutation = useUserPlaylistUpdateMutation();

    const value = useMemo(
        () => ({
            tracks,
            setTracks
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [tracks]
    );

    const onSubmit = (playlist?: Playlist) => {
        if (!playlist) {
            logger.warn('missing playlist object');

            return;
        }

        const isNew = !playlist.id;
        let message = '';

        const exists =
            isNew && checkIfPlaylistNameExists(playlists, playlist.name);

        const matched = !isNew
            ? playlist.tracks?.filter((item: Track) => {
                  return (
                      tracks
                          .map(track => track.id)
                          .findIndex(
                              (trackId: TrackId) => item.id === trackId
                          ) !== -1
                  );
              })
            : [];
        const existsInPlaylist = !isNew && Boolean(matched?.length);

        if (exists) {
            message = `${playlist.name} already exists, choose a different name`;
        } else if (existsInPlaylist) {
            message = `${matched
                ?.map(item => item.title)
                .join(', ')} already in ${playlist.name} playlist`;
        } else {
            const options = {
                onSuccess: () => {
                    let message = `${tracks.length} track(s) added to ${playlist.name}.`;

                    if (playlists.length <= 1) {
                        message += '\nGo to Library to view your playlists';
                    }

                    dispatch(snackbarOpen(message));
                },
                onError: () => {
                    const message = `error while adding tracks to ${playlist.name}`;

                    dispatch(snackbarOpen(message));
                }
            };

            const data = {
                ...playlist,
                tracks
            };

            isNew
                ? userPlaylistCreateMutation.mutate(data, options)
                : userPlaylistUpdateMutation.mutate(data, options);
        }

        if (exists || existsInPlaylist) {
            dispatch(snackbarOpen(message));
        }

        onClose();
    };

    const onClose = () => {
        setTracks([]);
    };

    return (
        <AddToPlaylistContext.Provider value={value}>
            {children}
            {tracks.length > 0 && (
                <Suspense fallback={null}>
                    <AddToPlaylistDialog
                        open
                        onSubmit={onSubmit}
                        onClose={onClose}
                    />
                </Suspense>
            )}
        </AddToPlaylistContext.Provider>
    );
};

export default AddToPlaylistProvider;
