import logger from 'js-logger';
import { isTest } from 'helpers/test';

// Log messages will be written to the window's console.
logger.useDefaults();
// logger.setHandler(function (messages, context) {
//     // Send messages to a custom logging endpoint for analysis.
// });

if (isTest()) {
    logger.setLevel(logger.OFF);
}

export default logger;
