import { playlistCount } from 'queries/playlists';
import { trackCount } from 'queries/tracks';
import type { PlaylistId, TrackId } from 'types';
import logger from 'utilities/logger';

const audio = new Audio();

let isTrackCounted = false;
let trackCountTimerId: NodeJS.Timeout | number | undefined = undefined;
let isPlaylistCounted = false;
let playlistCountTimerId: NodeJS.Timeout | number | undefined = undefined;

const TRACK_COUNT_THRESHOLD_IN_SECS = 5;
const PLAYLIST_COUNT_THRESHOLD_IN_SECS = 60;

const onPlaying = () => {
    const trackId = audio.dataset.trackId! as unknown as TrackId;
    const paramType = audio.dataset.paramType!;
    const paramId = audio.dataset.paramId!;

    if (paramType === 'playlist' && paramId) {
        if (!isPlaylistCounted && !playlistCountTimerId) {
            clearTimeout(playlistCountTimerId);

            playlistCountTimerId = setTimeout(() => {
                if (paramId !== audio.dataset.paramId) {
                    playlistCountTimerId = undefined;

                    return;
                }

                playlistCount({
                    playlistId: paramId as PlaylistId
                })
                    .then(() => {
                        isPlaylistCounted = true;
                        playlistCountTimerId = undefined;
                    })
                    .catch(logger.error);
            }, PLAYLIST_COUNT_THRESHOLD_IN_SECS * 1000);
        }
    }

    if (trackId) {
        if (!isTrackCounted && !trackCountTimerId) {
            clearTimeout(trackCountTimerId);

            trackCountTimerId = setTimeout(() => {
                if (trackId !== (audio.dataset.trackId as unknown as TrackId)) {
                    trackCountTimerId = undefined;

                    return;
                }

                trackCount({ trackId })
                    .then(() => {
                        isTrackCounted = true;
                        trackCountTimerId = undefined;
                    })
                    .catch(logger.error);
            }, TRACK_COUNT_THRESHOLD_IN_SECS * 1000);
        }
    }
};

const resetTrackCount = () => {
    isTrackCounted = false;
};

const resetPlaylistCount = () => {
    isPlaylistCounted = false;
};

const webPlayerSeek = (seekTime: number) => {
    audio.currentTime = seekTime;
};

const getCurrentTime = () => {
    return typeof audio.currentTime === 'number' ? audio.currentTime : 0;
};

const onWebPlayerTimeUpdate = (callback: () => void) => {
    audio.addEventListener('timeupdate', callback);

    return () => {
        audio.removeEventListener('timeupdate', callback);
    };
};

const onWebPlayerEnded = (callback: () => void) => {
    audio.addEventListener('ended', callback);

    return () => {
        audio.removeEventListener('ended', callback);
    };
};

audio.addEventListener('playing', onPlaying);
audio.addEventListener('loadstart', resetTrackCount);

export {
    resetTrackCount,
    resetPlaylistCount,
    webPlayerSeek,
    getCurrentTime,
    onWebPlayerTimeUpdate,
    onWebPlayerEnded
};

export default audio;
