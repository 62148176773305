import { init, configureScope } from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { CaptureConsole } from '@sentry/integrations';
import type { Integration, Scope } from '@sentry/types';
import type { BrowserOptions } from '@sentry/react';
import { getAppInfo, getBrowserId, getSessionId } from 'helpers/app';
import { isCypress } from 'helpers/is-cypress';
import config from 'config';

const { version } = getAppInfo();

const SentryInit = () => {
    const options: BrowserOptions = {
        dsn: 'https://3b89f85ddaaf421dbb758c0621f19f27@o1186630.ingest.sentry.io/6306550',
        environment: config.ENV,
        release: `website@${version}`,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0
    };

    if (!isCypress()) {
        // if (config.ENV !== 'local') {
        const integrations: Integration[] = [
            new BrowserTracing({
                tracingOrigins: [
                    config.API_HOST || 'localhost',
                    config.CONTENT_HOST
                ]
            })
        ];

        if (config.ENV !== 'local') {
            integrations.push(
                new CaptureConsole({
                    levels: ['error', 'warn']
                })
            );
        }

        options.integrations = integrations;

        init(options);

        configureScope((scope: Scope) => {
            scope.setTags({
                // eslint-disable-next-line camelcase
                session_id: getSessionId(),
                // eslint-disable-next-line camelcase
                browser_id: getBrowserId()
            });
        });
        // }
    }
};

SentryInit();

export * from '@sentry/react';
