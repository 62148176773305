import { lazy } from 'react';

export default lazy(
    () => import(/* webpackChunkName: "dashboard" */ './Dashboard')
);

const Music = lazy(
    () => import(/* webpackChunkName: "dashboard-music" */ './music')
);

const Users = lazy(
    () => import(/* webpackChunkName: "dashboard-users" */ './users')
);

const Contents = lazy(
    () => import(/* webpackChunkName: "dashboard-contents" */ './contents')
);

export { Music, Users, Contents };
