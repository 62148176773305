import { v4 as uuid } from 'uuid';
import env from 'env';
import Cookies from 'overrides/js-cookie';
import type { BrowserId, SessionId } from 'types';

type AppInfo = {
    version: string;
    buildNumber: string;
    buildBranch: string;
    buildTime: string;
    buildReason: string;
    pullRequest: {
        source: string;
        target: string;
    };
    author: string;
    packageName: string;
};

const sessionId = uuid() as SessionId;
let browserId = Cookies.get('browserId') as BrowserId;

if (!browserId) {
    browserId = uuid() as BrowserId;
}

Cookies.set('browserId', browserId, {
    expires: 12 * 30 // 1 year
});

export const getAppInfo = (): AppInfo => {
    const {
        REACT_APP_BUILD_NUM = '',
        REACT_APP_BUILD_REASON = '',
        REACT_APP_BUILD_SOURCEBRANCH = '',
        REACT_APP_BUILD_SOURCEVERSIONAUTHOR = '',
        REACT_APP_BUILD_TIME = Date.now(),
        REACT_APP_GIT_HEAD = '',
        REACT_APP_PULLREQUEST_SOURCEBRANCH = '',
        REACT_APP_PULLREQUEST_TARGETBRANCH = '',
        REACT_APP_VERSION = '',
        REACT_APP_PACKAGE_NAME = '@epoh/website'
    } = env;

    return {
        version: `${REACT_APP_VERSION}-${REACT_APP_GIT_HEAD.substring(0, 7)}${
            REACT_APP_BUILD_NUM ? `+${REACT_APP_BUILD_NUM}` : ''
        }`,
        buildNumber: REACT_APP_BUILD_NUM,
        buildTime: new Date(REACT_APP_BUILD_TIME).toLocaleString('en-US', {
            timeZone: 'Africa/Lagos',
            timeZoneName: 'short'
        }),
        buildBranch: REACT_APP_BUILD_SOURCEBRANCH.replace('refs/heads/', ''),
        buildReason: REACT_APP_BUILD_REASON,
        pullRequest: {
            source: REACT_APP_PULLREQUEST_SOURCEBRANCH.replace(
                'refs/heads/',
                ''
            ),
            target: REACT_APP_PULLREQUEST_TARGETBRANCH.replace(
                'refs/heads/',
                ''
            )
        },
        author: REACT_APP_BUILD_SOURCEVERSIONAUTHOR,
        packageName: REACT_APP_PACKAGE_NAME
    };
};
export const getAppName = () => env.REACT_APP_NAME || '';

export const getAppRoot = () => {
    return document.getElementById('root') ?? document.body;
};

export const getBrowserId = () => {
    return browserId;
};

export const getSessionId = () => {
    return sessionId;
};

export const onUserInteraction = new Promise(resolve => {
    globalThis.addEventListener('click', resolve, {
        once: true
    });
});

export const getImagePlaceholder = () => {
    return '/static/images/1x1.gif';
};
