import type { Dispatch, SetStateAction } from 'react';
import { createContext } from 'react';
import type { Track } from 'types';

const AddToPlaylistContext = createContext<{
    tracks: Track[];
    setTracks: Dispatch<SetStateAction<Track[]>>;
}>({
    tracks: [],
    setTracks: () => {
        throw new Error('setTracks is not defined');
    }
});

AddToPlaylistContext.displayName = 'AddToPlaylistContext';
export default AddToPlaylistContext;
