import { memo } from 'react';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { getUserImage } from 'helpers/get-user-image';
import { useUser } from 'hooks/use-user';

type Props = {
    showInfo?: boolean;
    imageSize?: number;
};

const AccountInfo = ({ imageSize = 40, showInfo = true }: Props) => {
    const user = useUser();

    return (
        <div className='account-info flex' data-test-id='account-info'>
            <div className='flex flex-direction-row'>
                <Avatar
                    alt='user avatar'
                    style={{
                        marginRight: 5,
                        marginBottom: 5,
                        height: `${imageSize}px`,
                        width: `${imageSize}px`
                    }}
                    src={getUserImage(user)}
                />
                {showInfo && (
                    <div
                        className='flex'
                        style={{
                            minWidth: 0
                        }}
                    >
                        <Typography variant='body1'>
                            {`${user.firstName} ${user.lastName}`}
                        </Typography>
                        <div>
                            <Typography className='ellipsis' title={user.email}>
                                {user.email}
                            </Typography>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default memo(AccountInfo);
