import config from 'config';
import type { User } from 'types';
import { getUserPicture } from './get-user-picture';
import { gravatarUrl } from './gravatar-url';

export const getUserImage = (user: User, size = 40) => {
    if (user.image) {
        return `//${config.CDN_PREFIX}/c_crop,g_custom/${user.image}`;
    }

    const userPicture = getUserPicture(user);

    if (userPicture) {
        return userPicture;
    }

    return gravatarUrl(user.email, {
        size
    });
};
