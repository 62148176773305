import type { Middleware } from 'redux';
import { ON_ADD_TO_CART, SHARE } from 'actions/types';
import type { RootState, AppDispatch } from 'store';
import type Actions from 'actions';
import { snackbarOpen, musicCartAddItem } from 'actions';
import logger from 'utilities/logger';
import { logAnalyticsEvent } from 'overrides/firebase.analytics';
import { captureException } from 'overrides/sentry';

const supportsShare = Boolean(navigator.share);

const app: Middleware<{}, RootState, AppDispatch> =
    ({ dispatch, getState }) =>
    next =>
        async function app(action: Actions) {
            const result = next(action);

            switch (action.type) {
                case SHARE: {
                    const {
                        title,
                        url = window.location.href,
                        text = 'The best of Nigerian music'
                    } = action.payload;

                    if (supportsShare) {
                        try {
                            await navigator.share({
                                title,
                                text,
                                url
                            });

                            logAnalyticsEvent('share', {
                                method: 'native',
                                title,
                                text,
                                url
                            });
                        } catch (e) {
                            captureException(e);
                        }
                    } else {
                        logger.warn('navigator.share not supported');
                    }

                    break;
                }
                case ON_ADD_TO_CART: {
                    const { data, type, currency } = action.payload;

                    const { musicCart } = getState();

                    if (
                        musicCart.currency === currency ||
                        musicCart.quantity === 0
                    ) {
                        dispatch(musicCartAddItem(type, data, currency));
                    } else {
                        dispatch(
                            snackbarOpen(
                                "Can't add to cart, because of difference in currency"
                            )
                        );
                    }

                    break;
                }
                default:
            }

            return result;
        };

export default app;
