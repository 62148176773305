import { useMemo } from 'react';
import {
    createRoutesFromElements,
    createBrowserRouter,
    Route,
    RouterProvider,
    Outlet,
    useRouteError
} from 'react-router-dom';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import PageNotFound from 'pages/errors/PageNotFound';
import { usePreferences } from 'hooks/use-preferences';
import ThemeContext from 'contexts/theme';
import getTheme from 'theme';
import type { ColorSchemeOption } from 'types';
import env from 'env';
import type { PageRouteKeys } from 'shared/page-routes';
import PageRoutes from 'shared/page-routes';
import Restricted from 'components/Restricted';
import Shell from 'components/shell/Shell';
import ErrorMessage from 'components/ErrorMessage';
import queryClient from 'shared/query-client';
import { isTest } from 'helpers/test';

// value should match the theme value in index.html
let defaultTheme: ColorSchemeOption = 'dark';

if (document.documentElement) {
    defaultTheme = document.documentElement.dataset.theme as ColorSchemeOption;
}

const routeKeys = Object.keys(PageRoutes) as PageRouteKeys[];

const ErrorBoundary = () => {
    const error = useRouteError() as Error;

    return <ErrorMessage message={error?.message} />;
};

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route
            path='/'
            element={
                <Shell>
                    <Outlet />
                </Shell>
            }
            errorElement={<ErrorBoundary />}
        >
            {routeKeys.map(path => {
                if (!PageRoutes[path]) {
                    throw new Error(`Page route ${path} is not defined`);
                }

                const {
                    component: Component,
                    access,
                    key = path,
                    loader,
                    ...rest
                } = PageRoutes[path];

                const dataLoader = isTest() ? undefined : loader?.(queryClient);

                if (access) {
                    return (
                        <Route
                            key={key}
                            path={path}
                            element={
                                <Restricted {...rest} access={access}>
                                    <Component />
                                </Restricted>
                            }
                            loader={dataLoader}
                            errorElement={<ErrorBoundary />}
                        />
                    );
                }

                return (
                    <Route
                        key={key}
                        path={path}
                        element={<Component />}
                        loader={dataLoader}
                        errorElement={<ErrorBoundary />}
                    />
                );
            })}
            <Route path='*' element={<PageNotFound />} />
        </Route>
    )
);

const App = () => {
    const [getPreference, setPreference] = usePreferences();
    const colorScheme = getPreference<ColorSchemeOption>(
        'shell.theme',
        defaultTheme
    );

    const setColorScheme = (value: ColorSchemeOption) => {
        setPreference<ColorSchemeOption>('shell.theme', value);
    };

    const value = useMemo(
        () => ({
            colorScheme,
            setColorScheme
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [colorScheme]
    );

    return (
        <>
            <ThemeContext.Provider value={value}>
                <ThemeContext.Consumer>
                    {({ colorScheme }) => {
                        return (
                            <StyledEngineProvider injectFirst>
                                <ThemeProvider theme={getTheme(colorScheme)}>
                                    <CssBaseline />

                                    <RouterProvider router={router} />
                                </ThemeProvider>
                            </StyledEngineProvider>
                        );
                    }}
                </ThemeContext.Consumer>
            </ThemeContext.Provider>
            {env.NODE_ENV === 'development' && (
                <ReactQueryDevtools
                    initialIsOpen={false}
                    toggleButtonProps={{
                        style: {
                            bottom: 85,
                            right: 30,
                            height: 36,
                            left: 'initial'
                        }
                    }}
                />
            )}
        </>
    );
};

export default App;
