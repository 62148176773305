import type { Dispatch, SetStateAction } from 'react';
import { createContext } from 'react';
import type { Album, Artist, Currency, Track } from 'types';

export type BuyNowMusicData = {
    price: string;
    title: string;
    artist: Artist;
    album: Omit<Album, 'artist'>;
    year?: string;
    tracks: Track[];
    type: 'Album' | 'Track';
    currency: Currency;
    id: number;
};

const BuyNowMusicContext = createContext<{
    buyNowMusicData: BuyNowMusicData | null;
    setBuyNowMusicData: Dispatch<SetStateAction<BuyNowMusicData | null>>;
}>({
    buyNowMusicData: null,
    setBuyNowMusicData: () => {
        throw new Error('setBuyNowMusicData is not defined');
    }
});

BuyNowMusicContext.displayName = 'BuyNowMusicContext';

export default BuyNowMusicContext;
