import type {
    ReactElement,
    ReactNode,
    MouseEvent,
    FunctionComponent,
    JSXElementConstructor
} from 'react';
import { cloneElement, forwardRef, Children, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { nanoid } from 'nanoid';
import AccountCircle from '@mui/icons-material/AccountCircle';
import History from '@mui/icons-material/History';
import Album from '@mui/icons-material/Album';
import CalendarToday from '@mui/icons-material/CalendarToday';
import Home from '@mui/icons-material/Home';
import Info from '@mui/icons-material/Info';
import LiveHelp from '@mui/icons-material/LiveHelp';
import LibraryMusic from '@mui/icons-material/LibraryMusic';
import SettingsRounded from '@mui/icons-material/SettingsRounded';
import ArtistIcon from 'mdi-material-ui/AccountMusic';
import GenreIcon from 'mdi-material-ui/GuitarAcoustic';
import HeadsetIcon from '@mui/icons-material/Headset';
import TuneIcon from '@mui/icons-material/Tune';
import kebabCase from 'lodash/kebabCase';
import ROUTES from 'shared/routes';
import AccountInfo from 'components/AccountInfo';
import { useLayout } from 'hooks/use-layout';
import { getRemoteConfigBooleanValue } from 'overrides/firebase.remote-config';

type ListItemLinkProps = {
    icon?: ReactElement;
    primary?: string;
    to: string;
    divider?: boolean;
    className?: string;
    children?: ReactNode;
    disableGutters?: boolean;
    selected?: boolean;
    indent?: boolean;
    showIcon?: boolean;
};

const ListItemLink = ({
    children,
    className,
    disableGutters,
    divider,
    icon,
    showIcon = true,
    primary,
    selected,
    to = '#!'
}: ListItemLinkProps) => {
    const Anchor = useMemo(() => {
        // eslint-disable-next-line react/display-name
        return forwardRef<HTMLAnchorElement>((props, ref) => (
            <Link to={to} {...props} ref={ref} />
        )) as any;
    }, [to]);

    return (
        <ListItemButton
            sx={{
                py: 0.75
            }}
            data-test={kebabCase(primary)}
            component={Anchor}
            divider={divider}
            className={className}
            disableGutters={disableGutters || !showIcon}
            selected={selected}
        >
            {showIcon && icon && <ListItemIcon>{icon}</ListItemIcon>}
            {primary && <ListItemText primary={primary} inset={!showIcon} />}
            {children}
        </ListItemButton>
    );
};

type Props = {
    classPrefix: string;
    className?: string;
    isDesktopView?: boolean;
    id?: string;
    isLoggedIn: boolean;
    onClick?: (e: MouseEvent<HTMLUListElement>) => void;
};

const MenuList: FunctionComponent<React.PropsWithChildren<Props>> = ({
    className,
    classPrefix = 'menu',
    id = nanoid(10),
    isDesktopView = false,
    isLoggedIn,
    onClick
}) => {
    const classes = {
        [`${classPrefix}`]: true,
        [`${classPrefix}-isDesktopView`]: !!isDesktopView
    };
    const { isDesktop } = useLayout();
    const location = useLocation();
    const isMusicMixEnabled = getRemoteConfigBooleanValue('MusicMix');
    const isNewSideNavEnabled = getRemoteConfigBooleanValue('NewSideNav');

    if (className) {
        classes[`${className}`] = true;
    }

    const children: JSX.Element | ReactElement<ListItemLinkProps>[] = [
        <ListItemLink
            showIcon={!isNewSideNavEnabled}
            icon={<Home />}
            key='home'
            primary='Home'
            to={ROUTES.HOME}
        />,
        <ListItemLink
            showIcon={!isNewSideNavEnabled}
            icon={<HeadsetIcon />}
            key='DJ'
            primary='DJs'
            to={ROUTES.DJS}
        />,
        <ListItemLink
            showIcon={!isNewSideNavEnabled}
            icon={<CalendarToday />}
            key='shows'
            primary='Shows'
            to={ROUTES.SHOWS}
        />
    ];

    const collection = (
        <List
            key='collection'
            component='div'
            subheader={
                <ListSubheader
                    disableSticky
                    component='div'
                    sx={{ textIndent: 15, px: 1 }}
                    key='collection-subheader'
                >
                    COLLECTION
                </ListSubheader>
            }
        >
            <ListItemLink
                icon={<ArtistIcon />}
                key='artists'
                primary='Artists'
                to={ROUTES.ARTISTS}
            />
            <ListItemLink
                icon={<Album />}
                key='albums'
                primary='Albums'
                to={ROUTES.ALBUMS}
            />
            <ListItemLink
                icon={<GenreIcon />}
                key='genres'
                primary='Genres'
                to={ROUTES.GENRES}
            />
        </List>
    );

    if (isMusicMixEnabled) {
        children.splice(
            1,
            0,
            <ListItemLink
                showIcon={!isNewSideNavEnabled}
                icon={<TuneIcon />}
                key='music-mix'
                primary='Music Mix'
                to={ROUTES.MUSIC_MIX}
            />
        );
    }

    children.splice(
        4,
        0,
        ...(isNewSideNavEnabled ? [collection] : collection.props.children)
    );

    if (isLoggedIn) {
        children.push(
            <ListItemLink
                key='recents'
                to={ROUTES.RECENTS}
                primary='Recents'
                icon={<History />}
            />,
            <ListItemLink
                divider
                icon={<LibraryMusic />}
                key='library'
                primary='Library'
                to={ROUTES.LIBRARY}
            />,
            <ListItemLink
                icon={<SettingsRounded />}
                key='settings'
                primary='Settings'
                to={ROUTES.SETTINGS}
            />
        );
    }

    children.push(
        <ListItemLink
            icon={<Info />}
            key='about'
            primary='About'
            to={ROUTES.ABOUT}
        />,
        <ListItemLink
            icon={<LiveHelp />}
            key='faq'
            primary='FAQ'
            to={ROUTES.FAQ}
        />
    );

    return (
        <List
            className={classnames(classes)}
            id={id}
            onClick={onClick}
            data-test-id={id}
            component='nav'
        >
            {isLoggedIn && !isDesktopView && !isDesktop ? (
                <ListItemLink
                    className='menu-account'
                    disableGutters
                    divider
                    key='account'
                    to={ROUTES.SETTINGS}
                >
                    <AccountInfo />
                </ListItemLink>
            ) : null}
            {!isLoggedIn && !isDesktop && (
                <ListItemLink
                    divider
                    key='login'
                    to={ROUTES.LOGIN}
                    primary='Login or Register'
                    icon={<AccountCircle />}
                />
            )}
            {children.map(child => {
                type Child = typeof child;
                const currentPathname = location.pathname;
                const pathname = child.props?.to;

                if (child.props?.children) {
                    return cloneElement(child, {
                        children: Children.map(
                            child.props?.children as ReactElement<
                                any,
                                JSXElementConstructor<any>
                            >,
                            (innerChild: Child) => {
                                const pathname = innerChild?.props?.to;

                                if (currentPathname === pathname) {
                                    return cloneElement(innerChild, {
                                        selected: true,
                                        className: `${
                                            innerChild?.props?.className || ''
                                        } selected`
                                    });
                                }

                                return innerChild;
                            }
                        )
                    });
                }

                if (currentPathname === pathname) {
                    return cloneElement(child, {
                        selected: true,
                        className: `${child.props.className || ''} selected`
                    });
                }

                return child;
            })}
        </List>
    );
};

export default MenuList;
