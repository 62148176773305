let _player: cast.framework.RemotePlayer | null = null;
let _controller: cast.framework.RemotePlayerController | null = null;
let _context: cast.framework.CastContext | null = null;

const getPlayer = () => {
    if (!_player) {
        _player = new cast.framework.RemotePlayer();
    }

    return _player;
};

const getController = () => {
    if (!_controller) {
        _controller = new cast.framework.RemotePlayerController(getPlayer());
    }

    return _controller;
};

const getContext = () => {
    if (!_context) {
        _context = cast.framework.CastContext.getInstance();
    }

    return _context;
};

const castPlayerSeek = (seekTime: number) => {
    const player = getPlayer();
    const controller = getController();

    player.currentTime = seekTime;
    controller.seek();
};

const onCastPlayerTimeUpdate = (
    callback: (e: cast.framework.RemotePlayerChangedEvent<any>) => void
) => {
    const { CURRENT_TIME_CHANGED } = cast.framework.RemotePlayerEventType;
    const controller = getController();

    controller.addEventListener(CURRENT_TIME_CHANGED, callback);

    return () => {
        controller.removeEventListener(CURRENT_TIME_CHANGED, callback);
    };
};

const onCastPlayerVolumeChange = (
    callback: (e: cast.framework.RemotePlayerChangedEvent<any>) => void
) => {
    const { VOLUME_LEVEL_CHANGED } = cast.framework.RemotePlayerEventType;
    const controller = getController();

    controller.addEventListener(VOLUME_LEVEL_CHANGED, callback);

    return () => {
        controller.removeEventListener(VOLUME_LEVEL_CHANGED, callback);
    };
};

export {
    getContext,
    getController,
    onCastPlayerTimeUpdate,
    castPlayerSeek,
    onCastPlayerVolumeChange
};
export default getPlayer;
